@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  width: 100%;
  object-fit: cover;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
  text-decoration: none !important;
}

section:not(:nth-child(1)),
section:not(:nth-child(2)) {
  animation: sectionEnter .2s ease-in;
}

@keyframes sectionEnter {
  from {transform: translateY(10vh);}
  to {transform: translateY(0);}
}

/*-------------------------------*/
/*         custom classes        */
/*-------------------------------*/

.box-shadow-black {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .1), -1px -1px 4px rgba(0, 0, 0, .1);
}

.grid {
  display: grid;
  gap: var(--gap);
}

.grid.stacked > *{
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.align-items-center {align-items: center;}
.justify-items-center {justify-items: center;}

/* .justify-self-center {ju} */

.content-centered {
  display: grid;
  place-items: center;
}

.space-between {justify-content: space-between}

.span-1 {
  grid-column: span 1;
  grid-row: span 1;
}
.span-2 {
  grid-column: span 2;
  grid-row: span 2;
}
.span-1-col {
  grid-column: span 1;
}
.span-1-row {
  grid-row: span 1;
}
.span-2-col {
  grid-column: span 2;
}
.span-2-row {
  grid-row: span 2;
}

.flex {
  display: flex;
  gap: var(--gap);
}

.container,
.wrapper {
  width: 100%;
  margin-inline: auto;
}

/* padding */
.pd-inline-sm {padding-inline: .5rem}
.pd-inline-1 {padding-inline: 1rem}
.pd-inline-2 {padding-inline: 2rem}
.pd-inline-3 {padding-inline: 3rem}

.pd-block-sm {padding-block: .5rem}
.pd-block-1 {padding-block: 1rem}
.pd-block-2 {padding-block: 2rem}
.pd-block-3 {padding-block: 3rem}

.pd-sm {padding: .5rem}
.pd-1 {padding: 1rem}
.pd-2 {padding: 2rem}
.pd-3 {padding: 3rem}

/* margin */
.mg-inline-sm {margin-inline: .5rem}
.mg-inline-1 {margin-inline: 1rem}
.mg-inline-2 {margin-inline: 2rem}
.mg-inline-3 {margin-inline: 3rem}

.mg-block-sm {margin-block: .5rem}
.mg-block-1 {margin-block: 1rem}
.mg-block-2 {margin-block: 2rem}
.mg-block-3 {margin-block: 3rem}

.mg-sm {margin: .5rem}
.mg-1 {margin: 1rem}
.mg-2 {margin: 2rem}
.mg-3 {margin: 3rem}

/* widths */
.wdth100 {width: 100%;}
.wdth90 {width: 90vw;}
.wdth80 {width: 85vw;}
.wdth75 {width: 75vw;}
.wdth70 {width: 70vw;}
.wdth60 {width: 60vw;}
.wdth50 {width: 50vw;}
.wdth150 {width: 12.5vw;}

/* heights */
.height-auto {min-height: auto; height: auto;}

/* gaps */
.gap-small {gap: 0.25rem;}
.gap1 {gap: 1rem;}
.gap2 {gap: 2rem;}
.gap3 {gap: 3rem;}
.gap4 {gap: 4rem;}
.gap5 {gap: 5rem;}

.full-block {
  display: block;
}

:root {
  /* colors */
  --primary: 38 115 87;
  --secondary: 56 76 67;
  --tertiary: 154 176 166;
  --accent: 18 109 153;
  --light: 255 255 255;
  --dark: 0 0 0;
  --alpha: 1;

  /* font families */
  --lato: 'Lato', sans-serif;
  --poppins: 'Poppins', sans-serif;

  /* transitions */
  --transition-2: .2s ease-in-out;
}


/*-------------------------------*/
/*             type              */
/*-------------------------------*/
h1,
h2,
h3,
h4 {
  font-family: var(--lato);
  line-height: 1.2;
}

h5,
h6,
p {
  font-family: var(--poppins);
}

.fw900 {font-weight: 900;}
.fw700 {font-weight: 700;}
.fw600 {font-weight: 600;}
.fw400 {font-weight: 400;}
.fw300 {font-weight: 300;}
.normal {font-weight: normal;}
.light {font-weight: light;}
.bold {font-weight: bold;}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}


/*-------------------------------*/
/*            colors             */
/*-------------------------------*/

/* alpha value */
.alpha-full {--alpha: 1}
.alpha-mid {--alpha: .8}
.alpha-low {--alpha: .6}

/* background */
.bg__primary {
  background: rgb(var(--primary) / var(--alpha));
}
.bg__secondary {
  background: rgb(var(--secondary) / var(--alpha));
}
.bg__tertiary {
  background: rgb(var(--tertiary) / var(--alpha));
}
.bg__accent {
  background: rgb(var(--accent) / var(--alpha));
}
.bg__light {
  background: rgb(var(--light) / var(--alpha));
}
.bg__dark {
  background: rgb(var(--dark) / var(--alpha));
}
.bg__trans {
  background: transparent;
}

/* text */
.txt__primary {
  color: rgb(var(--primary) / var(--alpha));
}
.txt__secondary {
  color: rgb(var(--secondary) / var(--alpha));
}
.txt__tertiary {
  color: rgb(var(--tertiary) / var(--alpha));
}
.txt__accent {
  color: rgb(var(--accent) / var(--alpha));
}
.txt__light {
  color: rgb(var(--light) / var(--alpha));
}
.txt__dark {
  color: rgb(var(--dark) / var(--alpha));
}

/* button */
button,
.btn {
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
  transition: var(--transition-2);
  cursor: pointer;
  text-transform: capitalize;
  font-family: var(--lato);
  font-size: 1rem;
  border: 2px solid transparent;
  position: relative;
}
.round {
  border-radius: 5em;
}
.outlined {
  background-color: transparent;
}

/* form elements */
input {
  border: none;
  outline: none;
  padding: .5rem 1rem;
  font-size: 1rem;
  display: grid;
}
input[type='text'],
input[type='submit'] {
  border-radius: 2.5px;
}

/* loader */
.loader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(var(--light) / var(--alpha));
  z-index: 50;
  /* display: none; */
  display: grid;
  place-items: center
}

.loader.hidden {
  display: none;
}


/*-------------------------------*/
/*            header             */
/*-------------------------------*/
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: .3s ease-in-out;
}
header nav {
  justify-content: space-between;
  align-items: center;
}

nav .logo__area {
  flex-wrap: nowrap;
  align-items: center;
}

nav .logo__area img {
  width: 6vw;
  aspect-ratio: 1;
  object-fit: cover;
  transition: .2s ease-in-out;
}

nav button {
  border-radius: 5px;
}

/* tabs */
.tabs {
  position: absolute;
  right: 0;
  background: rgb(var(--light) / var(--alpha));
  width: 25%;
  box-shadow: -2.5px 2.5px 2px rgba(196, 196, 196, 0.303);
  transition: .2s ease-in-out;
}
.tabs.hidden {
  width: 0;
  overflow: hidden;
}
.tabs > *{
  padding: .5rem 1rem;
  display: grid;
  justify-content: center;
  align-self: center;
  font-weight: 500;
  font-family: var(--lato);
  transition: .2s ease-in-out;
  font-size: 1.15rem;
  border: transparent;
  outline: none;
}


@media (max-width: 500px) {
  .tabs > *{
    padding: 1.5rem;
  }

}

.tabs > *:not(:last-child):hover,
.tabs > *:not(:last-child):focus {
  /* background: rgb(var(--primary) / .1); */
}

.logo_tabs_area {
  display: grid;
  gap: 2rem;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  justify-items: space-between;
  width: 100%;
}

.tabs.desktop-tabs {
  display: grid;
  grid-auto-flow: column;
  position: relative;
  background: transparent;
  box-shadow: none;
  position: relative;
  width: 100%;
}

@media (min-width: 850px) {
  .tabs {
    display: none;
  }
  .tabs.desktop-tabs {
    display: grid;
  }
  .mobile_tab_activaor {
    display: none
  }
}

@media (max-width: 850px) {
  .tabs.desktop-tabs {
    display: none;
  }
  .tabs {
    display: block;
  }
}

.tabs > * + * {
  border-top: .05px solid rgb(var(--tertiary) / .25);
}


/* footer */
.upper__footer__area {
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

footer form {
  grid-template-columns: .8fr .2fr;
  justify-content: flex-start;
}

.socials {
  align-content: flex-start;
}

.copyright {
  justify-self: center;
  text-align: center;
}

/* cookie */
.cookie.hide {display: none; }
.cookie {
  z-index: 8;
  display: none;
  position: fixed;
  bottom: 5%;
  right: 0;
  width: 60%;
  padding: 1rem 2rem;
  color: rgb(49, 48, 48);
}
.cookie button {
  padding: .5rem .75rem;
  position: absolute;
  top: 0;
  right: 0;
}

/* headings */

.section__heading  {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.section__heading::before,
.section__heading::after {
  content: "";
  background: rgb(var(--primary));
  display: inline-block;
  height: 2px;
  width: 5vw;
}

.section__heading::before {
  align-self: center;
}


/* page starter */
.starter {
  grid-template-columns: 1.25fr 1.5fr;
  align-items: center;
  justify-content: space-between;
  padding-inline-start: 4rem;
}


.starter__infro {
  --gap: 1rem
}

.starter__infro .slogan {
  max-inline-size: 30ch;
}

@media (min-width: 850px) {
  .starter {
    margin-block-start: 15vh;
  } 
  .starter__infro .slogan {
    max-inline-size: 40ch;
    font-size: 2.2rem;
    /* line-height:  */
  }
}

.starter__infro .btn {
  justify-self: flex-start;
  font-size: 1.2rem;
}

.starter .img__area img {
  width: 40vw;
  aspect-ratio: 1;
  object-fit: cover;
}

.starter .img__area {
  mask: url('./components/UG.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask: url('./components/UG.svg');
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  width: 40vw;
  aspect-ratio: 1;
}

/* bacsi needs */
.basic__needs {
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  --gap: 1rem
}

.basic_needs_card {
  grid-template-columns: .4fr .6fr;
  --gap: 0;
  align-content: flex-start;
}

.basic_needs_card .icon {
  font-size: 3rem;
}

/* causes */
.causes {
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.cause__card {
  display: grid;
  border-radius: 5%;
  align-content: flex-start;
  animation: cardEnter .2s ease-in;
}

.cause__card .title {
  cursor: pointer;
  line-height: 1.75rem;
}


/* events */
.events {
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  --gap: .15rem;
  transition: .2s ease-in-out;
}

.event__card {
  border-radius: 5px;
  overflow: hidden;
  transition: .1s ease-in-out;
}

.events img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  /* transition: .1s ease-in-out; */
}

.events .card__content {
  opacity: 0;
  align-content: center;
  justify-content: flex-start;
  --gap: 1rem
}

.card__content .card__title {
  cursor: pointer;
}

/* .event__card:hover,
.event__card:focus {
  grid-column: span 2;
  grid-row: span 1;
}

.event__card:hover img {
  /* aspect-ratio: 2 / 1; */
/* } */

.event__card:hover .card__content {
  opacity: 1;
  background-color: rgb(var(--secondary) / .8);
}

/* .event__card:hover + .event__card  {
  display: none;
}  */

/* about us */
.about__us {
  grid-template-columns: 1.25fr .25fr 1fr;
  --gap: 0;
  align-items: center;
  justify-items: center;
  padding-inline-start: 1.5rem;
}

.about__us .content {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  border: 2.5px solid rgb(var(--tertiary) / var(--alpha));
}

.about__us .about__us__img {
  grid-column: 2 / -1;
  grid-row: 1 / 2;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  width: 30vw;
}

/* article page */
.article__img {
  /* height: 75vh; */
  aspect-ratio: 2 / 1;
  object-fit: cover;
}

.article__content {
  grid-template-columns: 2fr .8fr;
  align-items: flex-start;
  margin-block-start: 1.5rem
}

@media (max-width: 850px) {
  .article__content {
    margin-block-start: 5rem
  } 
}

.article__description {
  justify-items: flex-start;
}

.article__side__infor {
  justify-items: stretch;
  align-items: flex-start;
}

table {
  border-collapse: collapse;
}

td, th {
  border: 1px solid rgb(var(--tertiary) / var(--alpha));
  text-align: left;
  padding: .5rem;
}

tr td:first-child {
  font-weight: 600;
  text-transform: capitalize;
}

.extra__img {
  grid-template-columns: repeat(2, 1fr);
  gap: .25rem;
}

.extra__img img {
  height: 20vh;
  aspect-ratio: 1;
  object-fit: cover;
}

/* MEDIA QUERIES */
@media (max-width: 850px) {
  .grid-tb {display: grid}
  .flex-tb {display: flex}
  .block-tb {display: block}
  .block-sm {display: none}

  /* padding */
  .pd-inline-sm-tb {padding-inline: .5rem}
  .pd-inline-1-tb {padding-inline: 1rem}
  .pd-inline-2-tb {padding-inline: 2rem}
  .pd-inline-3-tb {padding-inline: 3rem}

  .pd-block-sm-tb {padding-block: .5rem}
  .pd-block-1-tb {padding-block: 1rem}
  .pd-block-2-tb {padding-block: 2rem}
  .pd-block-3-tb {padding-block: 3rem}

  .pd-sm-tb {padding: .5rem}
  .pd-1-tb {padding: 1rem}
  .pd-2-tb {padding: 2rem}
  .pd-3-tb {padding: 3rem}

  /* margin */
  .mg-inline-sm-tb {margin-inline: .5rem}
  .mg-inline-1-tb {margin-inline: 1rem}
  .mg-inline-2-tb {margin-inline: 2rem}
  .mg-inline-3-tb {margin-inline: 3rem}

  .mg-block-sm-tb {margin-block: .5rem}
  .mg-block-1-tb {margin-block: 1rem}
  .mg-block-2-tb {margin-block: 2rem}
  .mg-block-3-tb {margin-block: 3rem}

  .mg-sm-tb {margin: .5rem}
  .mg-1-tb {margin: 1rem}
  .mg-2-tb {margin: 2rem}
  .mg-3-tb {margin: 3rem}

  /* widths */
  .wdth100-tb {width: 100%;}
  .wdth90-tb {width: 90vw;}
  .wdth80-tb {width: 85vw;}
  .wdth70-tb {width: 70vw;}
  .wdth60-tb {width: 60vw;}
  .wdth50-tb {width: 50vw;}
  .wdth150-tb {width: 12.5vw;}

  /* heights */
  .height-auto-tb {min-height: auto; height: auto;}

  /* gaps */
  .gap-small-tb {gap: 0.25rem;}
  .gap1-tb {gap: 1rem;}
  .gap2-tb {gap: 2rem;}
  .gap3-tb {gap: 3rem;}
  .gap4-tb {gap: 4rem;}
  .gap5-tb {gap: 5rem;}

  
  .starter {
    padding-inline-start: 1rem;
    overflow: hidden;
  }

  /* header */
  header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background : white;
    box-shadow : 0 1px 3px rgba(0, 0, 0, 0.205);
  }

  /* about us */
  .about__us .about__us__img {
    display: none;
  }
  
  .about__us {
    display: block;
  }
}

@media (max-width: 500px) {
  .grid-mb {display: grid}
  .flex-mb {display: flex}
  .block-mb {display: block}

  /* padding */
  .pd-inline-sm-mb {padding-inline: .5rem}
  .pd-inline-1-mb {padding-inline: 1rem}
  .pd-inline-2-mb {padding-inline: 2rem}
  .pd-inline-3-mb {padding-inline: 3rem}

  .pd-block-sm-mb {padding-block: .5rem}
  .pd-block-1-mb {padding-block: 1rem}
  .pd-block-2-mb {padding-block: 2rem}
  .pd-block-3-mb {padding-block: 3rem}

  .pd-sm-mb {padding: .5rem}
  .pd-1-mb {padding: 1rem}
  .pd-2-mb {padding: 2rem}
  .pd-3-mb {padding: 3rem}

  /* margin */
  .mg-inline-sm-mb {margin-inline: .5rem}
  .mg-inline-1-mb {margin-inline: 1rem}
  .mg-inline-2-mb {margin-inline: 2rem}
  .mg-inline-3-mb {margin-inline: 3rem}

  .mg-block-sm-mb {margin-block: .5rem}
  .mg-block-1-mb {margin-block: 1rem}
  .mg-block-2-mb {margin-block: 2rem}
  .mg-block-3-mb {margin-block: 3rem}

  .mg-sm-mb {margin: .5rem}
  .mg-1-mb {margin: 1rem}
  .mg-2-mb {margin: 2rem}
  .mg-3-mb {margin: 3rem}

  /* widths */
  .wdth100-mb {width: 100%;}
  .wdth90-mb {width: 90vw;}
  .wdth80-mb {width: 85vw;}
  .wdth70-mb {width: 70vw;}
  .wdth60-mb {width: 60vw;}
  .wdth50-mb {width: 50vw;}
  .wdth150-mb {width: 12.5vw;}

  /* heights */
  .height-auto-mb {min-height: auto; height: auto;}

  /* gaps */
  .gap-small-mb {gap: 0.25rem;}
  .gap1-mb {gap: 1rem;}
  .gap2-mb {gap: 2rem;}
  .gap3-mb {gap: 3rem;}
  .gap4-mb {gap: 4rem;}
  .gap5-mb {gap: 5rem;}

  /* about us */
  .about__us {
    padding-inline-start: 0;
  }
  .about__us .content {
    grid-column: 1 / -1;
  }
  
  .about__us .about__us__img {
    display: none;
  }

  /* tabs */
  .tabs {
    width: 70%;
  }

  /* footer */
  footer form {
    grid-template-columns: 1fr;
  }
  footer {
    justify-self: center;
    text-align: center;
  }
  footer form input[type='submit']{
    justify-self: center;
    width: 70%;
  }
  .cookie {
    width: 90%;
  }

  /* page starter */
  .starter {
    margin-block-start: 10vh;
    grid-template-columns: 1fr;
    grid-template-rows: 1;
    padding-inline-start: 0;
    padding-inline: 0 !important;
  }
  .starter__infro .btn {
    justify-self: center
  }

  .starter__infro {
    grid-column: 1 / 2;
    grid-row: 1 / -1;
    z-index: 3;
    display: grid;
    justify-content: center;
    justify-items: center;
    text-align: center;
  }
  
  .starter__infro .slogan ,
  .starter__infro .slogan__sub {
    max-inline-size: 20ch;
  }
  
    .starter__infro .slogan {
      line-height: 35px;
      max-inline-size: none;
    }

  .starter__infro .slogan__sub {
    line-height: 30px;
  }
  
  .starter .img__area img {
    width: 100%;
  }
  
  .starter .img__area {
    grid-column: 1 / -1;
    grid-row: 1 / 3;
    width: 100%;
    transform: translateX(100px) translateY(-40px) rotate(-65deg);
    display: none
  }

  /* article page */
  .article__img {
    height: 60vh;
    aspect-ratio: 1 / 2;
    object-fit: cover;
  }
  .article__content {
    grid-template-columns: 1fr
  }

  /* event card */
  .events {
    grid-template-columns: repeat(2, 1fr);
    --gap: .15rem;
    transition: .2s ease-in-out;
  }
  
  .event__card.inview .card__content {
    opacity: 1;
    background-color: rgb(var(--secondary) / .8);
    transition: .2s ease-in-out;
  }
  
  .lower {font-size: 1.05rem}
}